"use client";
import { Typography, Grid, Container, Box } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { FC, useEffect, useState } from "react";
import { withFirebase } from "@/components/Firebase";
import ramboll from "../assets/ramboll.webp";
import spits from "../assets/spits.webp";
import terra from "../assets/terra.webp";
import { useRouter } from "next/router";
import { FIND_RUN } from "@/constants/routes";
import Image from "next/image";

const classes = {
    title: {
        fontSize: { xs: "24px", sm: "35px", md: "40px" },
        fontWeight: 800,
        color: "white",
    },
};

interface MasterclassHeaderProps {
    firebase: any;
}

const WelcomeHeader: FC<MasterclassHeaderProps> = ({ firebase }) => {
    const [index, set_index] = useState(0);

    const { t, lang } = useTranslation("translation");
    const router = useRouter();

    const [images, set_images] = useState([ramboll.src, spits.src, terra.src]);
    const totalItems = 3;
    const [text, set_text] = useState([
        "Ramboll Stockholm Halvmarathon",
        "Spitsbergen Marathon",
        "Terra Raetica Trails Tour Festival",
    ]);
    const [url, set_url] = useState([
        "Stockholm_Halvmarathon",
        "Spitsbergen_Marathon",
        "TerraRaeticaTrailsTourFestival-1ststage",
    ]);

    const goToEvent = () => {
        router.push("/run/" + url[index]);
    };

    const goToSearch = () => {
        router.push(FIND_RUN);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            set_index((prevIndex) => (prevIndex + 1) % totalItems);
        }, 5000); // 5000ms = 5 seconds
    
        return () => clearInterval(interval); // Cleanup on unmount
      }, [totalItems]);

    // useEffect(() => {
    //     set_index(Math.floor(Math.random() * 3));
    // }, []);

    return (
        <Box>
            <Box
                sx={{
                    position: "relative",
                    width: "100%",
                    height: { xs: "auto" },
                }}
            >
                <Image
                    src={images[index]}
                    loading="eager"
                    alt="Background"
                    layout="fill" // This makes the image fill the parent div
                    objectFit="cover" // This ensures the image covers the entire div
                    quality={100} // Optional: Adjust the image quality
                    priority // Optional: Prioritize loading for background images
                />
                <Box>
                    <Container
                        maxWidth="xl"
                        sx={{
                            px: { lg: "50px" },
                            py: "60px",
                            position: "relative",
                            height: { xs: "250px", md: "400px", lg: "600px" },
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="center"
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                lg={12}
                                sx={{
                                    textAlign: "left",
                                    backgroundColor: "rgba(24, 24, 24, 0.4)",
                                    borderRadius: "20px",
                                    p: { xs: 2, md: 5 },
                                    cursor: "pointer",
                                }}
                                onClick={goToSearch}
                                order={{ xs: 1, sm: 2 }}
                            >
                                <Typography variant="h3" component="h1" mb={1} sx={classes.title}>
                                    {t("frontPage.headerTitle", null, { default: "" })}
                                </Typography>
                                <Typography variant="h3" component="h1" mb={1} sx={classes.title}>
                                    {t("frontPage.subTitle", null, { default: "" })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                    <Typography
                        variant="h5"
                        sx={{
                            color: "#FCFCFC",
                            fontWeight: "500",
                            zIndex: 10,
                            cursor: "pointer",
                            pl: 2,
                            position: "relative"
                        }}
                        onClick={goToEvent}
                    >
                        {text[index]}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default withFirebase(WelcomeHeader);
